/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useLayoutEffect } from "react";
import DropDown from "src/Components/FAQ/DropDown";
import SideOptions from "src/Components/FAQ/SideOptions";
import SecondaryNav from "src/Components/Navbar/SecondaryNav";
import {
  faqGeneral,
  faqGettingStarted,
  faqPayments,
  faqSecurity,
  faqTroubleshooting,
} from "src/constants";
import useWindowSize from "src/Hooks/useWindowSize";

const Faq = () => {
  const size = useWindowSize();
  const [height, setHeight] = useState(0);
  const options = [
    {
      name: "General",
    },
    {
      name: "Getting Started",
    },
    {
      name: "Payments",
    },
    {
      name: "Security",
    },
    {
      name: "Troubleshooting",
    },
  ];
  const [selected, setSelected] = useState(options[0]);

  useLayoutEffect(() => {
    setHeight(size.height - 56);
  }, [size]);

  return (
    <div className="h-full min-h-full overflow-hidden">
      <SecondaryNav
        menuItems={[
          { name: "Home", route: "/" },
          { name: "GitHub", link: "https://github.com/diba-io/bitmask-core/" },
        ]}
      />
      <div
        style={{
          height,
          minHeight: height,
        }}
        className="w-full min-h-screen m-auto overflow-y-auto"
      >
        <div className="flex flex-col w-11/12 m-auto">
          <h1 className="m-auto mt-3 mb-6 text-2xl font-bold text-white sm:text-4xl font-integral">
            FAQ
          </h1>
          <div className="flex flex-col w-full mx-auto lg:flex-row gap-6 md:gap-9 xl:gap-20">
            <div>
              <SideOptions
                options={options}
                value={selected}
                setValue={setSelected}
              />
            </div>
            <div className="flex-grow-default">
              {selected.name === "General" && <DropDown faqs={faqGeneral} />}
              {selected.name === "Getting Started" && (
                <DropDown faqs={faqGettingStarted} />
              )}
              {selected.name === "Payments" && <DropDown faqs={faqPayments} />}
              {selected.name === "Security" && <DropDown faqs={faqSecurity} />}
              {selected.name === "Troubleshooting" && (
                <DropDown faqs={faqTroubleshooting} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
