/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import TextField from "src/Components/Inputs/TextField";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import WalletSelectDropdown from "src/Components/Inputs/WalletSelectDropdown";
import TransactionFeeDropdown from "src/Components/Inputs/TransactionFeeDropdown";
import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import PageWrapper2 from "src/Components/Layout/Wrappers/PageWrapper2";
import ErrorModal from "src/Components/Modals/Error";
import TextArea from "src/Components/Inputs/TextArea";
import { Location } from "src/types";
import Mixpanel from "src/lib/Mixpanel";
import * as backend from "src/Backend";
import { addBalance, getFeeRate } from "src/Hooks/util";
import FullScreenModal from "src/Components/Modals/FullScreenModal";
import ScanQR from "src/Components/Modals/Children/ScanQR";
import { CARBONADO, DISABLE_LN, LNDHUBX } from "src/Backend/constants";
import SetFeeRate from "src/Components/Inputs/SetFeeRate";
import { isExtension } from "src/lib";

const Send = () => {
  const navigate = useNavigate();
  const network = window.localStorage.getItem("network");
  const location = useLocation() as Location;
  const {
    type,
    asset,
    walletData,
    vault,
    locationHistory,
    lnCredentials,
    hash,
  } = location.state;
  const [option, setOption] = useState(type || "");
  const [lnInvoice, setLnInvoice] = useState("");
  const [lnBalance, setLnBalance] = useState(0);
  const [fee, setFee] = useState(0);
  const [optimalFee, setOptimalFee] = useState(0);
  const [openError, setOpenError] = useState(false);
  const [sendMax, setSendMax] = useState(false);
  const [openQrScanner, setOpenQrScanner] = useState(false);
  const [usernameInvoice, setUsernameInvoice] = useState({
    username: "",
    amount: 0,
  });
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [amount, setAmount] = useState(0);

  const [transaction, setTransaction] =
    useState<backend.bitcoin.TransactionDetails>({
      sender: {
        name: walletData.name,
        address: walletData.address,
      },
      recipient: {
        name: "",
        address: "",
        invoice: "",
      },
      assetType: "",
      asset: "",
      amount: 0,
      fee: 0,
      message: "",
    });

  const items = [
    {
      name: "wallet 1",
      address: "0ehjtyje2435345234523",
      balance: "45.56",
    },
    {
      name: "wallet 2",
      address: "0ejyje3776356363",
      balance: "0.534",
    },
    {
      name: "wallet 3",
      address: "ejytej44234jye5452345",
      balance: "23.34",
    },
    {
      name: "wallet 4",
      address: "0fewf02452354353425452",
      balance: "2.34",
    },
  ];

  const items2 = [
    {
      name: "1 satoshi/vByte",
      amount: "45.56",
    },
    {
      name: "1 satoshi/vByte",
      amount: "0.534",
    },
    {
      name: "1 satoshi/vByte",
      amount: "23.34",
    },
    {
      name: "1 satoshi/vByte",
      amount: "2.34",
    },
  ];
  const items3 = [
    {
      name: "1 satoshi/vByte",
      amount: "45.56",
    },
    {
      name: "1 satoshi/vByte",
      amount: "0.534",
    },
    {
      name: "1 satoshi/vByte",
      amount: "23.34",
    },
    {
      name: "1 satoshi/vByte",
      amount: "2.34",
    },
  ];

  useEffect(() => {
    if (option === "Bitcoin") {
      Mixpanel.track("Viewed Send Bitcoin");
    } else if (option === "Asset") {
      Mixpanel.track("Viewed Send Asset Transaction", {
        Asset: asset,
        Transaction: transaction,
      });
    }
  }, []);

  useEffect(() => {
    const getLnBalance = async () => {
      if (["Lightning", "Username"].includes(option) && LNDHUBX) {
        const tokens = await backend.lightning.auth(
          lnCredentials.login,
          lnCredentials.password
        );
        if ("error" in tokens) {
          console.error("ln auth token err");
        } else {
          const res = await backend.lightning.getBalance(tokens.token);
          setLnBalance(Number(res[0].balance));
        }
      }
    };
    getLnBalance();
  }, [option]);

  useEffect(() => {
    const setFeeRate = async () => {
      const feeRate = await getFeeRate();
      setOptimalFee(feeRate);
      setFee(feeRate);
    };
    setFeeRate();
  }, [option]);

  const handleSatSubmit = () => {
    const transactionData = {
      ...transaction,
      assetType: "sats",
    };
    if (transaction.amount >= 546) {
      if (Number(transaction.amount) % 1 !== 0) {
        setError({
          title: `Error creating transaction`,
          message: `You cannot send fractions of a ${
            network === "mainnet" ? "Sat" : "tSat"
          }. Please remove decimal places. If you are trying to introduce amount in bitcoins, convert to ${
            network !== "bitcoin" ? "tSats" : "Sats"
          }`,
        });
        setOpenError(true);
      } else if (
        Number(addBalance(walletData?.balance)) >= transaction.amount
      ) {
        transaction.fee = 1000;
        navigate("/confirm", {
          state: {
            ...location.state,
            transaction: transactionData,
            locationHistory: [...locationHistory, window.location.hash],
            feeRate: fee,
            type: option,
          },
        });
      } else {
        setError({
          title: `Error creating transaction`,
          message: "Insufficient funds",
        });
        setOpenError(true);
      }
    } else {
      setError({
        title: `Error creating transaction`,
        message: `You must send a minimum of 546 ${
          network !== "bitcoin" ? "tSats" : "Sats"
        }. Look into the dust limit and uneconomical outputs.`,
      });
      setOpenError(true);
    }
  };

  const handleTokenSubmit = () => {
    if (asset.balance && asset.balance >= BigInt(transaction.amount)) {
      // TODO: we have to check the data of the invoice and passs to next page
      const transactionData = {
        ...transaction,
        asset: asset.contractId,
        assetType: option === "UDA" ? "UDA" : "token",
      };
      navigate("/confirm", {
        state: {
          ...location.state,
          transaction: transactionData,
          feeRate: fee,
          locationHistory: [...locationHistory, window.location.hash],
        },
      });
    } else {
      setError({
        title: `Error creating transaction`,
        message: "Insufficient funds",
      });
      setOpenError(true);
    }
  };

  return (
    <>
      {option === "" && (
        <PageWrapper2
          className=""
          title="Send Bitcoin"
          handlePageBack={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, lnCredentials, hash },
            })
          }
          handleCancel={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, lnCredentials, hash },
            })
          }
          button={
            <RoundedButton
              className="w-full text-lg text-black border-2 border-black dark:text-yellow-500 dark:border-yellow-500"
              onClick={() =>
                navigate("/wallet", {
                  state: {
                    wallet: walletData.name,
                    vault,
                    lnCredentials,
                    hash,
                  },
                })
              }
            >
              Return to wallet
            </RoundedButton>
          }
        >
          <div className="flex flex-col justify-center w-11/12 m-auto space-y-4 xs:space-y-6">
            <RoundedButton
              className="text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default"
              onClick={() => setOption("Bitcoin")}
            >
              Send Bitcoin on-chain
            </RoundedButton>
            <RoundedButton
              className="text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => setOption("Lightning")}
              disabled={DISABLE_LN || !LNDHUBX}
            >
              Send Bitcoin Through Lightning Invoice
            </RoundedButton>
            <RoundedButton
              className="text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => setOption("Username")}
              disabled={DISABLE_LN || !LNDHUBX}
            >
              Send Bitcoin Through Lightning Username
            </RoundedButton>
            <RoundedButton
              className="hidden text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => setOption("Asset")}
              disabled={!CARBONADO}
            >
              Send Asset
            </RoundedButton>
            <RoundedButton
              className="hidden text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default"
              onClick={() => setOption("Invoice")}
            >
              Pay Invoice
            </RoundedButton>
          </div>
        </PageWrapper2>
      )}
      {option === "Bitcoin" && (
        <PageWrapper
          title={`Send on-chain ${network !== "bitcoin" ? "tSats" : "Sats"}`}
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory: [...locationHistory, window.location.hash],
              },
            })
          }
          handleCancel={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, hash, lnCredentials },
            })
          }
          handleSubmit={() => handleSatSubmit()}
        >
          <div className="w-full">
            <div className="hidden">
              {network === "bitcoin" && (
                <WalletSelectDropdown
                  label="From"
                  items={items}
                  transaction={transaction}
                  setTransaction={setTransaction}
                />
              )}
            </div>
            <div className="flex flex-row w-full m-auto">
              <div className="w-full">
                <h3 className="mb-1 text-sm font-semibold text-left text-gray-800 dark:text-gray-400">
                  To
                </h3>
                <form className="flex flex-row w-full">
                  <div className="flex flex-col justify-center mx-auto flex-grow-default">
                    <input
                      className="w-full p-3 mx-auto text-sm placeholder-gray-600 bg-gray-200 dark:bg-newdarkmode-700 xs:text-base focus:bg-gray-300 rounded-md focus:outline disabled:opacity-50 disabled:cursor-not-allowed focus:text-gray-900 dark:placeholder-gray-400 dark:text-gray-500 dark:focus:text-gray-500 focus:placeholder-gray-600 focus:ring-1 focus:ring-gray-500 dark:focus:ring-newdarkmode-600"
                      onChange={(e) =>
                        setTransaction({
                          ...transaction,
                          recipient: {
                            ...transaction.recipient,
                            address: e.target.value,
                            name: "recipient",
                          },
                        })
                      }
                      value={transaction.recipient.address}
                      placeholder="Enter recipient's address"
                    />
                  </div>
                  <RoundedButton
                    className="w-auto py-3 ml-3 text-base text-black bg-yellow-500 sm:w-30 flex-shrink-default whitespace-nowrap"
                    onClick={() => {
                      navigator.getUserMedia =
                        navigator.getUserMedia ||
                        navigator.webkitGetUserMedia ||
                        navigator.mozGetUserMedia;

                      if (navigator.getUserMedia) {
                        navigator.getUserMedia(
                          { audio: false, video: { width: 1280, height: 720 } },
                          (stream) => true,
                          (err) => {
                            if (err.message) {
                              setError({
                                title: "Error Scanning QR",
                                message: `${err.name}: ${err.message}`,
                              });
                              setOpenError(true);
                            }
                          }
                        );
                      } else {
                        setError({
                          title: "Error Opening Camera",
                          message:
                            "getUserMedia is not supported on this device",
                        });
                        setOpenError(true);
                      }
                      setOpenQrScanner(true);
                    }}
                  >
                    Scan QR
                  </RoundedButton>
                </form>
              </div>
            </div>
            <div className="flex flex-row w-full m-auto mt-4">
              <div className="w-full">
                <h3 className="mb-1 text-sm font-semibold text-left text-gray-800 dark:text-gray-400">
                  Amount in {network !== "bitcoin" ? "tSats" : "Sats"}
                </h3>
                {sendMax ? (
                  <div className="flex flex-nowrap -mb-1">
                    <p className="w-full mt-3 text-base sm:text-lg font-light text-left text-gray-900 truncate dark:text-white">
                      Sending maximum amount: {walletData.balance.confirmed}{" "}
                      {network !== "bitcoin" ? "tSats" : "Sats"}
                    </p>
                    <RoundedButton
                      className="w-auto py-3 ml-3 text-base text-black bg-red-600 sm:w-30 flex-shrink-default whitespace-nowrap"
                      onClick={() => {
                        setSendMax(false);
                        setAmount(0);
                        setTransaction({
                          ...transaction,
                          amount: 0,
                        });
                      }}
                    >
                      Cancel
                    </RoundedButton>
                  </div>
                ) : (
                  <form className="flex flex-row w-full">
                    <div className="flex flex-col justify-center mx-auto flex-grow-default">
                      <input
                        className="w-full p-3 mx-auto text-sm placeholder-gray-600 bg-gray-200 dark:bg-newdarkmode-700 xs:text-base focus:bg-gray-300 rounded-md focus:outline disabled:opacity-50 disabled:cursor-not-allowed focus:text-gray-900 dark:placeholder-gray-400 dark:text-gray-500 dark:focus:text-gray-500 focus:placeholder-gray-600 focus:ring-1 focus:ring-gray-500 dark:focus:ring-newdarkmode-600"
                        type="number"
                        onChange={(e) => {
                          setAmount(Number(e.target.value));
                          setTransaction({
                            ...transaction,
                            amount: Number(e.target.value),
                          });
                        }}
                        placeholder="Enter amount"
                      />
                    </div>
                    <RoundedButton
                      className="w-auto py-3 ml-3 text-base text-black bg-yellow-500 sm:w-30 flex-shrink-default whitespace-nowrap"
                      onClick={() => {
                        if (walletData.balance.confirmed >= 546) {
                          setSendMax(true);
                          setAmount(walletData.balance.confirmed);
                          setTransaction({
                            ...transaction,
                            amount: walletData.balance.confirmed,
                          });
                        } else {
                          setError({
                            title: "Available balance too low",
                            message: `Available balance is too low, you must send a minimum of 546 ${
                              network !== "bitcoin" ? "tSats" : "Sats"
                            }. Look into the dust limit and uneconomical outputs. Your available balance: ${
                              walletData.balance.confirmed
                            }.`,
                          });
                          setOpenError(true);
                        }
                      }}
                    >
                      Send Max
                    </RoundedButton>
                  </form>
                )}
              </div>
            </div>
            <div className="hidden">
              {network === "bitcoin" && (
                <>
                  <div className="grid grid-cols-2 gap-4">
                    <TransactionFeeDropdown
                      label="Gas Fee"
                      type="fee"
                      items={items2}
                      transaction={transaction}
                      setTransaction={setTransaction}
                    />
                    <TransactionFeeDropdown
                      label="Gas Limit"
                      type="limit"
                      items={items3}
                      transaction={transaction}
                      setTransaction={setTransaction}
                    />
                  </div>
                  <h3 className="mb-1 text-sm font-semibold text-gray-600 dark:text-gray-500">
                    Transaction Fees
                  </h3>
                </>
              )}
            </div>
            <TextArea
              type="text"
              label="Note"
              placeholder="Enter note"
              className="w-full p-3"
              rows={1}
              onChange={(e) =>
                setTransaction({ ...transaction, note: e.target.value })
              }
              onKeyDown={(e) =>
                e.key === "Enter" ? () => handleSatSubmit() : null
              }
            />
            <SetFeeRate
              label="Set Fee Rate"
              optimalFee={optimalFee}
              fee={fee}
              onFeeChange={(f) => setFee(f)}
              onFeeRefresh={(f) => setOptimalFee(f)}
            />
          </div>
        </PageWrapper>
      )}
      {option === "Lightning" && (
        <PageWrapper
          title="Pay Lightning Invoice"
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory: [...locationHistory, window.location.hash],
              },
            })
          }
          handleCancel={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, lnCredentials, hash },
            })
          }
          handleSubmit={() => {
            try {
              const lnAmount =
                // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
                require("light-bolt11-decoder").decode(lnInvoice).sections[2]
                  .value * 0.001;
              if (lnBalance >= lnAmount / 100_000_000) {
                navigate("/lightningconfirm", {
                  state: {
                    wallet: walletData.name,
                    vault,
                    hash,
                    lnCredentials,
                    usernameInvoice: null,
                    lnInvoice,
                  },
                });
              } else {
                setError({
                  title: "Error Paying Invoice",
                  message: "LN wallet balance too low",
                });
                setOpenError(true);
              }
            } catch (err) {
              setError({
                title: "Error Paying Invoice",
                message:
                  `${(err as Error)?.name}: ${(err as Error)?.message}` ||
                  "Unhandled exception",
              });
              setOpenError(true);
            }
          }}
        >
          <div className="w-full">
            <div className="w-full">
              <h3 className="mb-1 text-sm font-semibold text-left text-gray-800 dark:text-gray-500">
                LN Invoice
              </h3>
              <form className="flex flex-row w-full">
                <div className="flex flex-col justify-center mx-auto flex-grow-default">
                  <input
                    type="text"
                    className="w-full p-3 text-base placeholder-gray-600 bg-gray-300 dark:focus:ring-newdarkmode-600 dark:focus:border-newdarkmode-600 dark:bg-newdarkmode-700 focus:bg-gray-350 rounded-md focus:outline disabled:opacity-50  disabled:cursor-not-allowed focus:text-gray-900 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:text-gray-500"
                    placeholder="Enter recipient's invoice"
                    onChange={(e) => setLnInvoice(e.target.value)}
                    value={lnInvoice}
                  />
                </div>
                <RoundedButton
                  className="w-auto py-3 ml-3 text-base text-black bg-yellow-500 sm:w-30 flex-shrink-default whitespace-nowrap"
                  onClick={() => {
                    navigator.getUserMedia =
                      navigator.getUserMedia ||
                      navigator.webkitGetUserMedia ||
                      navigator.mozGetUserMedia;

                    if (navigator.getUserMedia) {
                      navigator.getUserMedia(
                        { audio: false, video: { width: 1280, height: 720 } },
                        (stream) => true,
                        (err) => {
                          if (err.message) {
                            setError({
                              title: "Error Scanning QR",
                              message: `${err.name}: ${err.message}`,
                            });
                            setOpenError(true);
                          }
                        }
                      );
                    } else {
                      setError({
                        title: "Error Opening Camera",
                        message: "getUserMedia is not supported on this device",
                      });
                      setOpenError(true);
                    }
                    setOpenQrScanner(true);
                  }}
                >
                  Scan QR
                </RoundedButton>
              </form>
            </div>
            <p className="w-full mt-6 text-base font-medium text-center text-gray-900 truncate dark:text-gray-500">
              <span className="font-light">LN wallet balance:</span>{" "}
              {lnBalance
                ? lnBalance.toLocaleString(undefined, {
                    minimumFractionDigits: 8,
                  })
                : 0}{" "}
              {network !== "bitcoin" ? "tBTC" : "BTC"}
            </p>
          </div>
        </PageWrapper>
      )}
      {option === "Username" && (
        <PageWrapper
          title="Send With LN Username"
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory: [...locationHistory, window.location.hash],
              },
            })
          }
          handleCancel={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, lnCredentials, hash },
            })
          }
          handleSubmit={async () => {
            try {
              if (lnBalance >= usernameInvoice.amount / 100_000_000) {
                navigate("/lightningconfirm", {
                  state: {
                    wallet: walletData.name,
                    vault,
                    hash,
                    lnCredentials,
                    usernameInvoice,
                    lnInvoice: null,
                  },
                });
              } else {
                setError({
                  title: "Error Paying Invoice",
                  message: "LN wallet balance too low",
                });
                setOpenError(true);
              }
            } catch (err) {
              setError({
                title: "Error Paying Invoice",
                message:
                  `${(err as Error)?.name}: ${(err as Error)?.message}` ||
                  "Unhandled exception",
              });
              setOpenError(true);
            }
          }}
        >
          <div className="w-full">
            <div className="w-full">
              <TextField
                type="text"
                label="Username"
                placeholder="Enter username"
                className="w-full p-3"
                onChange={(e) =>
                  setUsernameInvoice({
                    ...usernameInvoice,
                    username: e.target.value,
                  })
                }
              />
              <TextField
                type="number"
                label="Amount"
                placeholder="Enter amount (Sats)"
                className="w-full p-3"
                onChange={(e) =>
                  setUsernameInvoice({
                    ...usernameInvoice,
                    amount: e.target.value,
                  })
                }
              />
            </div>
            <p className="w-full mt-6 text-base font-medium text-center text-gray-900 truncate dark:text-gray-500">
              <span className="font-light">LN wallet balance:</span>{" "}
              {lnBalance
                ? lnBalance.toLocaleString(undefined, {
                    minimumFractionDigits: 8,
                  })
                : 0}{" "}
              {network !== "bitcoin" ? "tBTC" : "BTC"}
            </p>
          </div>
        </PageWrapper>
      )}
      {(option === "Asset" || option === "UDA") && (
        <PageWrapper
          title={`Send ${option}`}
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory: [...locationHistory, window.location.hash],
              },
            })
          }
          handleCancel={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, hash, lnCredentials },
            })
          }
          handleSubmit={() => handleTokenSubmit()}
        >
          <div className="w-full">
            <div className="hidden">
              {network === "bitcoin" && (
                <WalletSelectDropdown
                  label="From"
                  items={items}
                  transaction={transaction}
                  setTransaction={setTransaction}
                />
              )}
            </div>
            <div className="w-full">
              <h3 className="mb-1 text-sm font-semibold text-left text-gray-800 dark:text-gray-500">
                To
              </h3>
              <form className="flex flex-row w-full">
                <div className="flex flex-col justify-center mx-auto flex-grow-default">
                  <input
                    className="w-full p-3 mx-auto text-sm placeholder-gray-600 bg-gray-200 xs:text-base focus:bg-gray-300 rounded-md focus:outline disabled:opacity-50 disabled:cursor-not-allowed focus:text-gray-900 dark:bg-newdarkmode-700 dark:placeholder-gray-400 dark:text-gray-500 dark:focus:text-gray-500 focus:placeholder-gray-600 focus:ring-1 focus:ring-gray-500 dark:focus:ring-newdarkmode-600"
                    onChange={async (e) => {
                      const { contractId, amount: invoiceAmount } =
                        await backend.rgb.decodeInvoice(e.target.value);
                      // TODO: check if contractId is correct and amount is affordable
                      setTransaction({
                        ...transaction,
                        amount: Number(invoiceAmount),
                        asset: contractId,
                        recipient: {
                          ...transaction.recipient,
                          invoice: e.target.value,
                          name: "recipient",
                        },
                      });
                    }}
                    placeholder="Enter recipient's invoice"
                  />
                </div>
                <RoundedButton
                  className="w-auto py-3 ml-3 text-base text-black bg-yellow-500 sm:w-30 flex-shrink-default whitespace-nowrap"
                  onClick={() => {
                    navigator.getUserMedia =
                      navigator.getUserMedia ||
                      navigator.webkitGetUserMedia ||
                      navigator.mozGetUserMedia;

                    if (navigator.getUserMedia) {
                      navigator.getUserMedia(
                        { audio: false, video: { width: 1280, height: 720 } },
                        (stream) => true,
                        (err) => {
                          if (err.message) {
                            setError({
                              title: "Error Scanning QR",
                              message: `${err.name}: ${err.message}`,
                            });
                            setOpenError(true);
                          }
                        }
                      );
                    } else {
                      setError({
                        title: "Error Opening Camera",
                        message: "getUserMedia is not supported on this device",
                      });
                      setOpenError(true);
                    }
                    setOpenQrScanner(true);
                  }}
                >
                  Scan QR
                </RoundedButton>
              </form>
            </div>
            <div className="hidden">
              {network === "bitcoin" && (
                <>
                  <div className="grid grid-cols-2 gap-4">
                    <TransactionFeeDropdown
                      label="Gas Fee"
                      type="fee"
                      items={items2}
                      transaction={transaction}
                      setTransaction={setTransaction}
                    />
                    <TransactionFeeDropdown
                      label="Gas Limit"
                      type="limit"
                      items={items3}
                      transaction={transaction}
                      setTransaction={setTransaction}
                    />
                  </div>
                  <h3 className="mb-1 text-sm font-semibold text-gray-600 dark:text-gray-500">
                    Transaction Fees
                  </h3>
                </>
              )}
            </div>
            <TextArea
              type="text"
              label="Note"
              placeholder="Enter note"
              className="w-full p-3"
              onChange={(e) =>
                setTransaction({ ...transaction, note: e.target.value })
              }
            />
            <SetFeeRate
              label="Set Fee Rate"
              optimalFee={optimalFee}
              fee={fee}
              onFeeChange={(f) => setFee(f)}
              onFeeRefresh={(f) => setOptimalFee(f)}
            />
          </div>
        </PageWrapper>
      )}
      {option === "Invoice" && (
        <PageWrapper
          title={`Send ${option}`}
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory: [...locationHistory, window.location.hash],
              },
            })
          }
          handleCancel={() =>
            navigate("/wallet", {
              state: { wallet: walletData.name, vault, hash, lnCredentials },
            })
          }
          handleSubmit={() =>
            navigate("/confirm", {
              state: {
                walletData,
                vault,
                lnCredentials,
                transaction,
                hash,
              },
            })
          }
        >
          <div className="w-full">
            <div className="hidden">
              {network === "bitcoin" && (
                <WalletSelectDropdown
                  label="From"
                  items={items}
                  transaction={transaction}
                  setTransaction={setTransaction}
                />
              )}
            </div>
            <TextField
              label="To"
              onChange={(e) =>
                setTransaction({
                  ...transaction,
                  recipient: {
                    ...transaction.recipient,
                    address: e.target.value,
                    name: "recipient",
                  },
                })
              }
              placeholder="Enter recipient's address"
              className="w-full p-3"
            />
            <TextField
              label="Amount"
              type="number"
              onChange={(e) =>
                setTransaction({ ...transaction, amount: e.target.value })
              }
              placeholder="Enter amount"
              className="w-full p-3"
            />
            <div className="hidden">
              {network === "bitcoin" && (
                <>
                  <div className="grid grid-cols-2 gap-4">
                    <TransactionFeeDropdown
                      label="Gas Fee"
                      type="fee"
                      items={items2}
                      transaction={transaction}
                      setTransaction={setTransaction}
                    />
                    <TransactionFeeDropdown
                      label="Gas Limit"
                      type="limit"
                      items={items3}
                      transaction={transaction}
                      setTransaction={setTransaction}
                    />
                  </div>
                  <h3 className="mb-1 text-sm font-semibold text-gray-600 dark:text-gray-500">
                    Transaction Fees
                  </h3>
                </>
              )}
            </div>
            <TextArea
              type="text"
              label="Note"
              placeholder="Enter note"
              className="w-full p-3"
              onChange={(e) =>
                setTransaction({ ...transaction, note: e.target.value })
              }
            />
          </div>
        </PageWrapper>
      )}
      <ErrorModal
        open={openError}
        setOpen={setOpenError}
        message={error.message}
        title={error.title}
      />
      <FullScreenModal open={openQrScanner} setOpen={setOpenQrScanner}>
        <ScanQR
          setQr={(res) => {
            switch (option) {
              case "Bitcoin":
                setTransaction({
                  ...transaction,
                  recipient: {
                    ...transaction.recipient,
                    address: res,
                    name: "recipient",
                  },
                });
                break;
              case "Lightning":
                try {
                  const lnAmount =
                    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
                    require("light-bolt11-decoder").decode(res).sections[2]
                      .value * 0.001;
                  if (lnBalance > lnAmount / 100_000_000) {
                    navigate("/lightningconfirm", {
                      state: {
                        wallet: walletData.name,
                        vault,
                        hash,
                        lnCredentials,
                        lnInvoice: res,
                      },
                    });
                  } else {
                    setError({
                      title: "Error Paying Invoice",
                      message: "LN wallet balance too low",
                    });
                    setOpenError(true);
                  }
                } catch (err) {
                  setError({
                    title: "Error Paying Invoice",
                    message:
                      `${(err as Error)?.name}: ${(err as Error)?.message}` ||
                      "Unhandled exception",
                  });
                  setOpenError(true);
                }
                break;
              case "Asset":
              case "UDA":
                if (
                  asset.balance &&
                  asset.balance >= BigInt(transaction.amount)
                ) {
                  const transactionData = {
                    ...transaction,
                    recipient: {
                      ...transaction.recipient,
                      invoice: res,
                      name: "recipient",
                    },
                    asset: asset.contractId,
                    assetType: option === "UDA" ? "UDA" : "token",
                  };
                  navigate("/confirm", {
                    state: {
                      ...location.state,
                      transaction: transactionData,
                      feeRate: fee,
                      locationHistory: [
                        ...locationHistory,
                        window.location.hash,
                      ],
                    },
                  });
                } else {
                  setError({
                    title: `Error creating transaction`,
                    message: "Insufficient funds",
                  });
                  setOpenError(true);
                }
                break;
              default:
                return null;
            }
            return null;
          }}
          open={openQrScanner}
          setOpen={setOpenQrScanner}
        />
      </FullScreenModal>
    </>
  );
};

export default Send;
