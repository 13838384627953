/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* global chrome */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import FullWidthTextField from "src/Components/Inputs/FullWidthTextField";
import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import ErrorModal from "src/Components/Modals/Error";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import Copy from "src/Components/Icons/Copy";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import { Location } from "src/types";
import { sealPrefix, ifaceTokens } from "src/constants";
import * as backend from "src/Backend";
import { isExtension } from "src/lib";
import LeftLabelToggle from "src/Components/Inputs/LeftLabelToggle";

// TODO: create new events for mixpanel tracking plan
const CreateAsset = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [publicAsset, setPublicAsset] = useState(false);
  const [genesis, setGenesis] = useState("");
  const [asset, setAsset] = useState<backend.rgb.IssueResponse>({
    contractId: "",
    contract: { legacy: "", strict: "", armored: "" },
    iimplId: "",
    ticker: "",
    name: "",
    description: "",
    precision: 0,
    supply: BigInt(0),
    iface: ifaceTokens,
    issueUtxo: "",
    genesis: { legacy: "", strict: "", armored: "" },
    meta: {},
  });
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const { walletData, vault, hash, lnCredentials, assetUtxos } = location.state;
  const handleSubmit = async () => {
    try {
      const res = await backend.rgb.issueContract(vault.private.nostrPrv, {
        ticker: asset.ticker,
        name: asset.name,
        description: asset.description,
        supply: asset.supply,
        precision: asset.precision,
        seal: `${sealPrefix}:${assetUtxos.currentUtxoTokens}`,
        iface: ifaceTokens,
      });
      if (publicAsset) {
        await fetch(
          `${isExtension ? "https://beta.bitmask.app/" : "/"}createasset`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(
              {
                asset: {
                  creatorXpubkh: vault.public.xpubkh,
                  ticker: asset.ticker,
                  name: asset.name,
                  description: asset.description,
                  supply: asset.supply,
                  precision: asset.precision,
                  contractId: res.contractId,
                  contractGenesis: res.genesis.armored,
                },
                auth: {
                  xpubkh: vault.public.xpubkh,
                  xprvkh: vault.private.xprvkh,
                },
              },
              (key, value) =>
                typeof value === "bigint" ? value.toString() : value
            ),
          }
        );
      }
      setAsset(res);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setError({ title: "Error creating asset", message: err.toString() });
      setOpen(true);
    }
  };

  if (asset.contract.armored !== "") {
    return (
      <div className="flex justify-center w-full h-full mx-auto min-h-page 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
        <div className="flex flex-col justify-between h-full m-auto text-center min-h-page">
          <h1 className="mt-6 text-2xl font-normal text-center text-black xs:text-3xl lg:text-4xl flex-shrink-default dark:text-green-500">
            Asset Creation Success
          </h1>
          <img
            className="m-auto my-8 w-36 h-36 sm:w-64 sm:h-64 lg:my-12"
            src="/images/greenCheck.png"
            alt="green check mark"
          />
          <div className="text-gray-900 dark:text-gray-500">
            <div className="flex flex-col w-full mb-6">
              <h1 className="px-3 my-3 text-xl font-normal text-gray-900 truncate dark:text-gray-300 flex-shrink-default">
                Contract:
              </h1>
              <button
                type="button"
                className="flex w-auto px-3 py-1 m-auto font-normal text-center text-gray-900 bg-gray-300 rounded-lg cursor-pointer hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-400 max-w-88 sm:max-w-152 dark:bg-newdarkmode-700 flex-nowrap"
                onClick={() => {
                  navigator.clipboard.writeText(asset.contract.armored);
                  setOpen(true);
                }}
                title="Copy contract"
              >
                <div className="flex w-full m-auto flex-nowrap">
                  <p className="w-[85%] my-auto text-base font-light truncate sm:w-full">
                    {asset.contract.armored}
                  </p>
                  <Copy className="my-auto ml-1 w-7 h-7 sm:w-9 sm:h-9" />
                </div>
              </button>
            </div>
          </div>
          <div className="flex justify-center w-9/12 py-6 mx-auto gap-6 sm:pb-12 lg:pb-20">
            <RoundedButton
              className="w-full text-base bg-yellow-500 border-2 border-yellow-500 text-newdarkmode-900"
              onClick={() => {
                navigate("/wallet", {
                  state: {
                    wallet: walletData.name,
                    vault,
                    hash,
                    lnCredentials,
                  },
                });
              }}
            >
              Okay
            </RoundedButton>
          </div>
        </div>
        <CopiedToClipboard open={open} setOpen={setOpen} />
      </div>
    );
  }

  return (
    <PageWrapper
      title="Create Asset"
      handlePageBack={() =>
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, hash, lnCredentials },
        })
      }
      handleCancel={() =>
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, hash, lnCredentials },
        })
      }
      handleSubmit={async () => {
        if (asset.ticker.length <= 2) {
          setError({
            title: "Value Error",
            message: `Ticker must be at least 3 characters. Ticker length: ${asset.ticker.length}.`,
          });
          setOpen(true);
        } else if (asset.ticker.length > 40) {
          setError({
            title: "Value Error",
            message: `Ticker can be a maximum of 40 characters. Ticker length: ${asset.ticker.length}.`,
          });
          setOpen(true);
        } else {
          setLoading(true);
          await handleSubmit();
        }
      }}
      loading={loading}
    >
      <div className="flex flex-col justify-center w-full h-auto">
        <FullWidthTextField
          label="Ticker"
          type="text"
          className="w-full p-2 mx-0 xs:p-3 min-w-11/12 xs:min-w-9/12 flex-grow-default"
          placeholder="Ticker"
          onChange={(e) =>
            setAsset({ ...asset, ticker: e.target.value.toUpperCase() })
          }
        />
        <FullWidthTextField
          label="Asset Name"
          type="text"
          className="w-full p-2 mx-0 xs:p-3 min-w-11/12 xs:min-w-9/12 flex-grow-default"
          placeholder="Name"
          onChange={(e) => setAsset({ ...asset, name: e.target.value })}
        />
        <FullWidthTextField
          label="Total Supply"
          type="number"
          className="w-full p-2 mx-0 xs:p-3 min-w-11/12 xs:min-w-9/12 flex-grow-default"
          placeholder="Supply"
          onChange={(e) =>
            setAsset({ ...asset, supply: BigInt(e.target.value) })
          }
        />
        <FullWidthTextField
          label="Precision"
          type="number"
          className="w-full p-2 mx-0 mb-1.5 xs:p-3 min-w-11/12 xs:min-w-9/12 flex-grow-default"
          placeholder="Precision"
          onChange={(e) =>
            setAsset({ ...asset, precision: parseInt(e.target.value, 10) })
          }
        />
        <LeftLabelToggle
          label="Set asset visibility"
          description="Do you want this asset to be public, and listed on a public BitMask Asset Registry?"
          enabled={publicAsset}
          setEnabled={setPublicAsset}
        />
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </PageWrapper>
  );
};

export default CreateAsset;
