/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";

import * as backend from "src/Backend";
import { Location } from "src/types";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import Mixpanel from "src/lib/Mixpanel";
import CopyButton from "src/Components/Buttons/CopyButton";
import Loader from "src/Components/Loaders/Loader";

// TODO: replace QR lib with one that can handle a 4k+ char QR code

const Success = () => {
  const [open, setOpen] = useState(false);
  const [lnTxPending, setLnTxPending] = useState(true);
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    wallet,
    vault,
    consignment,
    transaction,
    asset,
    lnInvoice,
    lnCredentials,
    hash,
  } = location.state;
  const network = window.localStorage.getItem("network");

  if (transaction.assetType === "Accepted") {
    Mixpanel.track("Accepted Asset Transaction", {
      Asset: asset,
      Transaction: transaction,
    });
  } else if (transaction.assetType === "token") {
    Mixpanel.track("Sent Asset", { Transaction: transaction, Asset: asset });
    Mixpanel.track("Viewed Send Asset Consignment");
  } else if (transaction.assetType === "sats") {
    Mixpanel.track("Viewed Transaction Completion Page", {
      Transaction: transaction,
    });
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (lnInvoice) {
      const interval = setInterval(async () => {
        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
        const paymentHash = require("light-bolt11-decoder").decode(lnInvoice)
          .sections[5].value;
        const check = await backend.lightning.checkPayment(paymentHash);
        setLnTxPending(!check);
        if (check) clearInterval(interval);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, []);

  return (
    <div className="flex flex-col justify-center w-full h-full m-auto 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
      <div className="w-full h-auto m-auto text-center">
        {transaction.assetType !== "Asset" &&
        transaction.assetType !== "Assets" &&
        transaction.assetType !== "token" &&
        transaction.assetType !== "UDA" ? (
          <>
            {transaction.assetType === "Lightning" ? (
              <>
                <h1 className="m-auto text-2xl font-normal text-center text-black max-w-10/12 xs:text-3xl lg:text-4xl flex-grow-default dark:text-green-500">
                  Invoice{" "}
                  {lnTxPending && lnInvoice ? "pending" : "paid successfully"}
                </h1>
                {lnTxPending && lnInvoice ? (
                  <Loader className="mx-auto my-16 w-18 h-18 sm:w-24 sm:h-24 lg:my-24" />
                ) : (
                  <img
                    className="m-auto my-8 w-36 h-36 sm:w-64 sm:h-64 lg:my-12"
                    src="/images/greenCheck.png"
                    alt="green check mark"
                  />
                )}
              </>
            ) : (
              <>
                <h1 className="m-auto text-2xl font-normal text-center text-black max-w-10/12 xs:text-3xl lg:text-4xl flex-grow-default dark:text-green-500">
                  Transaction submitted successfully
                </h1>
                <img
                  className="m-auto my-8 w-36 h-36 sm:w-64 sm:h-64 lg:my-12"
                  src="/images/greenCheck.png"
                  alt="green check mark"
                />
                {transaction.assetType === "sats" && (
                  <div
                    className="flex justify-center w-auto mx-auto my-6 text-base font-semibold text-center text-gray-900 xs:mb-9 max-w-9/12 dark:text-gray-300"
                    title="View transaction on Mempool"
                  >
                    <a
                      href={`${
                        network === "signet"
                          ? "https://mutinynet.com/tx/"
                          : `https://diba.mempool.space/${
                              network !== "bitcoin" ? "testnet/" : ""
                            }tx/`
                      }${transaction.id}`}
                      target="_blank"
                      rel="noreferrer"
                      className="w-auto m-auto text-base font-medium text-gray-800 underline break-all xs:text-lg dark:text-green-500"
                    >
                      View Transaction {transaction.id} on Mempool
                    </a>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div className="flex flex-col justify-center w-full text-gray-900 dark:text-gray-500">
            <div className="w-full h-auto m-auto bg-gray-100 pt-1.5 hidden">
              <div className="w-auto h-auto py-6 m-auto sm:py-12">
                <QRCode
                  // eslint-disable-next-line react/jsx-curly-brace-presence
                  value={`https://bitmask.app/consignment?buffer=`}
                  className="max-w-[275px] sm:max-w-[375px] sm:w-[90%] h-auto m-auto"
                  size={256}
                  viewBox="0 0 256 256"
                />
              </div>
            </div>
            <p className="mx-auto mb-6 text-lg font-light text-center text-black dark:text-white max-w-10/12">
              Send consignment to the recipient to complete the transfer.
            </p>
            <div className="flex flex-col justify-center">
              <CopyButton
                className="max-w-10/12 m-auto pt-1.5"
                title="Consignment"
                handleOnClick={() => {
                  Mixpanel.track("Copied Consignment", {
                    Source: `Send ${transaction.assetType} Consignment`,
                  });
                  navigator.clipboard.writeText(consignment);
                  setOpen(true);
                }}
              >
                {consignment}
              </CopyButton>
            </div>
          </div>
        )}
        <RoundedButton
          className="w-8/12 py-3 mx-auto mt-6 text-base text-black bg-yellow-500 sm:mt-12 sm:text-lg sm:w-44"
          onClick={() =>
            navigate("/wallet", {
              state: { wallet, vault, lnCredentials, hash },
            })
          }
        >
          Close
        </RoundedButton>
      </div>
      <CopiedToClipboard open={open} setOpen={setOpen} />
    </div>
  );
};

export default Success;
