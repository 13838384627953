/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-empty-function */
/* global chrome */
import React, { Fragment, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";

import ReleaseNotes from "src/Components/Modals/Children/ReleaseNotes";
import Modal from "src/Components/Modals/Modal";
import { ThemeContext } from "src/Hooks/ThemeContext";
import Dots from "src/Components/Icons/Dots";
import LightDarkToggle from "src/Components/Inputs/LightDarkToggle";
import { classNames, delay } from "src/Hooks/util";
import Mixpanel from "src/lib/Mixpanel";
import { DefaultLocation, Location } from "src/types";
import lib, { isExtension } from "src/lib";
import { ifaceUdas, releaseNotes } from "src/constants";
import Twitter from "src/Components/Icons/Twitter";
import Telegram from "src/Components/Icons/Telegram";
import Discord from "src/Components/Icons/Discord";
import Instagram from "src/Components/Icons/Instagram";

export const defaultLocation: DefaultLocation = {
  state: {
    wallet: "",
    walletData: {
      wallet: "",
      name: "",
      address: "",
      balance: {
        immature: 0,
        trustedPending: 0,
        untrustedPending: 0,
        confirmed: 0,
      },
      transactions: [],
      utxos: [],
    },
    assetUtxos: {
      currentUtxoUdas: "",
      currentUtxoTokens: "",
    },
    vault: {
      mnemonic: "",
      private: {
        xprvkh: "",
        btcDescriptorXprv: "",
        btcChangeDescriptorXprv: "",
        rgbAssetsDescriptorXprv: "",
        rgbUdasDescriptorXprv: "",
        nostrPrv: "",
        nostrNsec: "",
      },
      public: {
        xpub: "",
        xpubkh: "",
        watcherXpub: "",
        btcDescriptorXpub: "",
        btcChangeDescriptorXpub: "",
        rgbAssetsDescriptorXpub: "",
        rgbUdasDescriptorXpub: "",
        nostrPub: "",
        nostrNpub: "",
      },
    },
    consignment: "",
    transaction: {
      amount: 0,
      asset: "",
      assetType: "",
      fee: 0,
      message: "",
      note: "",
      id: "",
      date: 0,
      action: "",
      status: "",
      sender: {
        name: "",
        address: "",
      },
      recipient: {
        name: "",
        address: "",
        invoice: "",
      },
    },
    asset: {
      contract: { legacy: "", strict: "", armored: "" },
      contractId: "",
      iface: "",
      supply: BigInt(1),
      precision: 1,
      iimplId: "",
      ticker: "",
      name: "",
      description: "",
      allocations: [],
      balance: BigInt(0),
      genesis: { legacy: "", strict: "", armored: "" },
      meta: {},
    },
    contractId: "",
    uda: {
      contractId: "",
      iimplId: "",
      iface: ifaceUdas,
      ticker: "",
      precision: 1,
      supply: BigInt(1),
      balance: BigInt(0),
      allocations: [],
      contract: { legacy: "", strict: "", armored: "" },
      name: "",
      description: "",
      genesis: { legacy: "", strict: "", armored: "" },
      meta: {},
    },
    type: "",
    funded: false,
    address: "",
    conversion: 0,
    message: "",
    tabid: 0,
    pubkeyHash: "",
    id: "",
    uid: "",
    source: {
      view: "",
      param: "",
    },
    locationHistory: [],
    contract: "",
    lnCredentials: {
      login: "",
      password: "",
      accessToken: "",
      refreshToken: "",
    },
    lnInvoice: "",
    tokensWalletFunded: false,
    loadingVault: false,
    tokensWalletAddress: "",
    udasWalletAddress: "",
    marketplaceAction: {
      title: "",
      description: "",
      call: "",
    },
    hash: "",
    network: localStorage.getItem("network") || "bitcoin",
    feeRate: 0,
    usernameInvoice: {
      username: "",
      amount: 0,
    },
    pendingAssetTransfers: false,
    pendingUdaTransfers: false,
  },
  key: "",
};

const { VERSION } = process.env;

const NavbarMenu = ({ className }) => {
  const navigate = useNavigate();
  let location = useLocation() as Location;
  const [checked, setChecked] = useState(false);
  const [newTab, setNewTab] = useState(false);
  const [openVersionPopup, setOpenVersionPopup] = useState(false);
  const { theme, setTheme } = useContext(ThemeContext);

  const onChange = () => {
    Mixpanel.track("Toggled Theme", {
      "Old Setting": theme,
      "New Setting": theme === "dark" ? "light" : "dark",
    });
    if (setTheme) setTheme(theme === "dark" ? "light" : "dark");
    setChecked(!checked);
  };

  const handleRedirectToFullscreenTab = () => {
    const win = window.open(
      window.location.href
        .replace(window.location.hash, "")
        .replace(window.location.search, ""),
      "_blank"
    );
    setNewTab(!newTab);
    win?.focus();
  };

  const items = [
    {
      name: (
        <div className="flex flex-nowrap">
          <span className="my-auto text-left flex-grow-default">
            Toggle theme
          </span>
          <div className="float-right m-auto ml-auto mr-1">
            <LightDarkToggle />
          </div>
        </div>
      ),
      key: "toggle theme",
      onClick: () => onChange(),
    },
    {
      name: "Fullscreen",
      key: "fullscreen",
      onClick: () => handleRedirectToFullscreenTab(),
      hidden: window.innerHeight > 600,
    },
    {
      name: "Add account",
      key: "add account",
      disabled: true,
    },
    {
      name: "Account details",
      key: "account details",
      onClick: () => navigate("/account"),
    },
    {
      name: "Settings",
      key: "settings",
      onClick: () => navigate("/settings"),
    },
    {
      name: "Lock wallet",
      key: "lock wallet",
      onClick: async () => {
        localStorage.setItem("lockWallet", "true");
        await lib.storageDel(["vault"]);
        window.sessionStorage.removeItem("hash");
        window.history.replaceState({}, "", window.location.href);
        location = defaultLocation;
        navigate("/signin", { replace: true });
      },
    },
    {
      name: "Homepage",
      key: "homepage",
      // onClick: () => navigate("/"),
      onClick: () => {
        localStorage.setItem("lockWallet", "false");
        localStorage.removeItem("lockWallet");
        navigate("/home", { replace: true });
      },
    },
    {
      name: "Contact support",
      key: "contact support",
      onClick: () => {
        Mixpanel.track("Tapped Contact Support");
        const win = window.open(
          "https://t.me/joinchat/Ajbxchsrj75kMDRi",
          "_blank"
        );
        win?.focus();
      },
    },
    {
      name: `BitMask v${VERSION}`,
      key: "bitmask version",
      onClick: () => setOpenVersionPopup(true),
    },
    {
      name: (
        <div className="flex m-auto font-normal text-gray-800 cursor-pointer flex-nowrap dark:text-white h-14">
          <a
            className="m-auto w-14"
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/bitmask_app?s=21&t=VH4nq5qpcV5iPRa2Z4gEyA"
          >
            <Twitter className="w-8 h-8 m-auto" />
          </a>
          <a
            className="m-auto w-14"
            target="_blank"
            rel="noreferrer"
            href="https://t.me/tryDIBA"
          >
            <Telegram className="m-auto w-11 h-11" />
          </a>
          <a
            className="m-auto w-14"
            target="_blank"
            rel="noreferrer"
            href="https://discord.gg/RzKxTJg24K"
          >
            <Discord className="w-6 h-6 m-auto" />
          </a>
          <a
            className="m-auto w-14"
            target="_blank"
            rel="noreferrer"
            href="https://instagram.com/trydiba?igshid=YWJhMjlhZTc="
          >
            <Instagram className="w-[1.35rem] h-[1.35rem] m-auto" />
          </a>
        </div>
      ),
      key: "socials",
      noMargin: true,
      social: true,
    },
  ];

  return (
    <Menu
      as="div"
      className="relative flex justify-center h-auto m-auto text-left"
    >
      {({ open }) => (
        <>
          <Menu.Button className={className} title="Toggle Menu">
            <Dots
              className="m-auto mt-1 text-gray-800 h-7 w-7 rotate-90 dark:text-gray-400"
              aria-hidden="true"
            />
          </Menu.Button>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 w-56 mt-2 bg-gray-100 shadow-lg origin-top-right rounded-md dark:bggradient ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
            >
              {items.map((item) => (
                <div key={item.key} className="">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        disabled={item.disabled}
                        className={classNames(
                          active && !item.social
                            ? "hover:bg-gray-200 dark:hover:bg-newdarkmode-800 text-black dark:text-gray-400"
                            : "text-gray-900 dark:text-gray-300",
                          item.hidden ? "hidden" : "",
                          item.noMargin ? "px-1 pb-1" : "px-4 py-4",
                          "block text-sm w-full h-auto text-left disabled:pointer-events-none disabled:text-gray-600 dark:disabled:text-gray-600 disabled:text-opacity-75"
                        )}
                        onClick={item.onClick ? () => item.onClick() : () => {}}
                      >
                        <div className="m-auto">{item.name}</div>
                      </button>
                    )}
                  </Menu.Item>
                </div>
              ))}
            </Menu.Items>
          </Transition>
          <Modal open={openVersionPopup} setOpen={setOpenVersionPopup}>
            <ReleaseNotes version={VERSION} releaseNotes={releaseNotes} />
          </Modal>
        </>
      )}
    </Menu>
  );
};

export default NavbarMenu;
