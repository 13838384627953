/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { classNames } from "src/Hooks/util";
import Twitter from "src/Components/Icons/Twitter";
import Telegram from "src/Components/Icons/Telegram";
import Discord from "src/Components/Icons/Discord";
import Instagram from "src/Components/Icons/Instagram";

const { CONTEXT = "" } = process.env;

const promos = ["MIXD Future Factory"];

const ReleaseNotes = ({ ...props }) => (
  <div className="px-6 pt-6 xs:px-9 xs:pt-9 dark:bg-transparent md:px-12 md:pt-12 pb-1.5 xs:pb-3">
    {!promos.includes(CONTEXT) && (
      <h1 className="text-xl border-gray-700 xs:text-3xl dark:text-white">
        BitMask v{props.version} release notes
      </h1>
    )}
    <ul
      className={classNames(
        promos.includes(CONTEXT) ? "" : "pt-6",
        "flex flex-col space-y-3"
      )}
    >
      {props.releaseNotes.map((note) => (
        <li
          className={classNames(
            promos.includes(CONTEXT) ? "text-center" : "text-left list-disc",
            "text-base font-light dark:text-gray-400 sm:text-lg lg:text-xl"
          )}
          key={JSON.stringify(note)}
        >
          {note}
        </li>
      ))}
    </ul>
    <div className="flex pt-6 font-normal text-gray-800 cursor-pointer flex-nowrap md:ml-auto dark:text-white xs:pt-9">
      <a
        className="w-24 m-auto"
        target="_blank"
        rel="noreferrer"
        href="https://twitter.com/bitmask_app?s=21&t=VH4nq5qpcV5iPRa2Z4gEyA"
      >
        <Twitter className="w-10 h-10 m-auto sm:w-10 sm:h-10" />
      </a>
      <a
        className="w-24 m-auto"
        target="_blank"
        rel="noreferrer"
        href="https://t.me/tryDIBA"
      >
        <Telegram className="m-auto w-14 h-14 sm:w-14 sm:h-14" />
      </a>
      <a
        className="w-24 m-auto"
        target="_blank"
        rel="noreferrer"
        href="https://discord.gg/RzKxTJg24K"
      >
        <Discord className="w-8 h-8 m-auto sm:w-8 sm:h-8" />
      </a>
      <a
        className="w-24 m-auto"
        target="_blank"
        rel="noreferrer"
        href="https://instagram.com/trydiba?igshid=YWJhMjlhZTc="
      >
        <Instagram className="m-auto w-7 h-7 sm:w-7 sm:h-7" />
      </a>
    </div>
  </div>
);

export default ReleaseNotes;
