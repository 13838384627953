import * as backend from "src/Backend";

export interface transferAssetsResponse {
  txid: string;
  consig: string;
}

export const transferAssets = async (
  vault: backend.bitcoin.Vault,
  asset: backend.rgb.ImportResponse,
  invoice: string,
  descriptorXpub: string,
  descriptorXprv: string,
  iface: string,
  changeTerminal: string,
  feeValue: number
): Promise<transferAssetsResponse> => {
  const { consig, psbt: rgbPsbt } = await backend.rgb.fullTransferAsset(
    vault.private.nostrPrv,
    {
      rgbInvoice: invoice,
      contractId: asset.contractId,
      iface,
      descriptor: descriptorXpub,
      changeTerminal,
      fee: { value: feeValue },
      bitcoinChanges: [],
    }
  );
  console.debug("transferAssets consignment:", consig);
  const { txid, sign } = await backend.rgb.psbtSignFile(
    vault.private.nostrPrv,
    {
      psbt: rgbPsbt,
      descriptors: [
        descriptorXprv,
        vault.private.btcDescriptorXprv,
        vault.private.btcChangeDescriptorXprv,
      ],
    }
  );
  if (sign) {
    return { txid, consig };
  }
  return {
    txid: "",
    consig,
  };
};

export const transferAssetsWithFeeRate = async (
  vault: backend.bitcoin.Vault,
  asset: backend.rgb.ImportResponse,
  invoice: string,
  descriptorXpub: string,
  descriptorXprv: string,
  iface: string,
  changeTerminal: string,
  feeRate: number
): Promise<transferAssetsResponse> => {
  const { consig, psbt: rgbPsbt } = await backend.rgb.fullTransferAsset(
    vault.private.nostrPrv,
    {
      rgbInvoice: invoice,
      contractId: asset.contractId,
      iface,
      descriptor: descriptorXpub,
      changeTerminal,
      fee: { feeRate },
      bitcoinChanges: [],
    }
  );
  console.debug("transferAssets consignment:", consig);
  const { txid, sign } = await backend.rgb.psbtSignFile(
    vault.private.nostrPrv,
    {
      psbt: rgbPsbt,
      descriptors: [
        descriptorXprv,
        vault.private.btcDescriptorXprv,
        vault.private.btcChangeDescriptorXprv,
      ],
    }
  );
  if (sign) {
    return { txid, consig };
  }
  return {
    txid: "",
    consig,
  };
};

export const fullTransferAssets = async (
  vault: backend.bitcoin.Vault,
  asset: backend.rgb.ImportResponse,
  transaction: backend.bitcoin.Activity,
  descriptorXpub: string,
  descriptorXprv: string,
  iface: string,
  seal: string,
  changeTerminal: string,
  feeValue: number
): Promise<transferAssetsResponse> => {
  const { invoice } = await backend.rgb.createInvoice(vault.private.nostrPrv, {
    contractId: asset.contractId,
    amount: BigInt(transaction.amount),
    iface,
    seal,
    params: {},
  });
  const transfersResponse = await transferAssets(
    vault,
    asset,
    invoice,
    descriptorXpub,
    descriptorXprv,
    iface,
    changeTerminal,
    feeValue
  );

  return transfersResponse;
};
