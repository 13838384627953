/* eslint-disable @typescript-eslint/no-explicit-any */
/* global chrome */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as backend from "src/Backend";
import { Vault } from "src/Backend/bitcoin";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import Mixpanel from "src/lib/Mixpanel";
import lib, { isExtension } from "src/lib";
import { getFeeRate } from "src/Hooks/util";
import SetFeeRate from "src/Components/Inputs/SetFeeRate";

interface Props {
  walletBalance: string;
  vault: Vault;
  tokensWalletAddress: string;
  udasWalletAddress: string;
  setOpenFundAssetsModal: any;
}

const FundAssets = ({ ...props }) => {
  const { walletBalance, vault, setOpenFundAssetsModal } = props as Props;
  const [fee, setFee] = useState(0);
  const [optimalFee, setOptimalFee] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    Mixpanel.track("View Fund Vault Confirmation");
    const setFeeRate = async () => {
      const feeRate = await getFeeRate();
      setOptimalFee(feeRate);
      setFee(feeRate);
    };
    setFeeRate();
  }, []);

  // TODO: calculate exact amount of sats needed to fund vault

  return (
    <div className="flex flex-col w-full max-w-3xl m-auto sm:px-6 sm:pt-3">
      {Number(walletBalance) >= 4000 ? (
        <div>
          {error.message === "" ? (
            <>
              <h1 className="w-10/12 mx-auto text-xl font-normal text-center md:text-2xl dark:text-white font-integral">
                Funding Assets Wallet
              </h1>
              <p className="w-10/12 mx-auto my-3 text-base font-light text-left text-black break-words sm:my-5 dark:text-gray-300">
                You are going to send{" "}
                {window.localStorage.getItem("network") !== "bitcoin"
                  ? "tSats"
                  : "Sats"}{" "}
                to your assets wallet to fund it and start receiving and sending
                assets
              </p>
              <div className="flex justify-center mx-auto max-w-10/12">
                <SetFeeRate
                  label="Set Fee Rate"
                  optimalFee={optimalFee}
                  fee={fee}
                  onFeeChange={(amount) => setFee(amount)}
                  onFeeRefresh={(amount) => setOptimalFee(amount)}
                />
              </div>
              <div className="w-8/12 mx-auto my-3 sm:w-5/12">
                <RoundedButton
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-black bg-yellow-500 rounded-md shadow-sm"
                  onClick={async () => {
                    try {
                      if (fee > optimalFee * 2 || fee < 1) {
                        setError({
                          title: "An error occurred while funding asset wallet",
                          message: `Fee rate is set out of range, allowed range is between 1 and ${
                            optimalFee * 2
                          } sats/vByte.`,
                        });
                      } else {
                        setLoading(true);
                        const [tokensAddress1, udasAddress1] =
                          await Promise.all([
                            backend.bitcoin.getNewAddress(
                              vault.private.rgbAssetsDescriptorXprv
                            ),
                            backend.bitcoin.getNewAddress(
                              vault.private.rgbUdasDescriptorXprv
                            ),
                          ]);
                        const [tokensAddress2, udasAddress2] =
                          await Promise.all([
                            backend.bitcoin.getNewAddress(
                              vault.private.rgbAssetsDescriptorXprv
                            ),
                            backend.bitcoin.getNewAddress(
                              vault.private.rgbUdasDescriptorXprv
                            ),
                          ]);
                        console.log("fund vault addresses:", {
                          tokensAddress1,
                          tokensAddress2,
                          udasAddress1,
                          udasAddress2,
                        });
                        const fundVault = await backend.bitcoin.fundVault(
                          vault.private.btcDescriptorXprv,
                          vault.private.btcChangeDescriptorXprv,
                          tokensAddress1,
                          tokensAddress2,
                          udasAddress1,
                          udasAddress2,
                          fee !== 0 ? fee : optimalFee
                        );
                        lib.storageSet({ funded: true }).then(() => {
                          Mixpanel.track("Funded Vault", {
                            Transaction: fundVault,
                          });
                          setOpenFundAssetsModal(false);
                          if (isExtension) {
                            chrome.runtime.sendMessage({
                              call: "reload_script_and_tabs",
                            });
                          }
                          navigate("/");
                        });
                      }
                    } catch (err: any) {
                      console.error(
                        "An error occurred while funding asset wallet:",
                        err
                      );
                      setLoading(false);
                      setError({
                        title: "An error occurred while funding asset wallet",
                        message: err?.message || err.toString(),
                      });
                    }
                  }}
                  loading={loading}
                >
                  Okay
                </RoundedButton>
                <RoundedButton
                  className="inline-flex justify-center w-full px-4 mt-3 text-base font-medium text-black bg-yellow-500 rounded-md shadow-sm"
                  onClick={() => {
                    setOpenFundAssetsModal(false);
                  }}
                >
                  Cancel
                </RoundedButton>
              </div>
            </>
          ) : (
            <div className="w-11/12 mx-auto my-3 sm:w-10/12">
              <h1 className="w-10/12 mx-auto text-lg font-normal text-center md:text-xl dark:text-white font-integral">
                {error.title}
              </h1>
              <p className="w-11/12 mx-auto my-3 text-base font-light text-gray-800 break-words dark:text-gray-300">
                {error.message}
              </p>
              <RoundedButton
                className="inline-flex justify-center w-full max-w-xs px-4 mt-3 text-base font-medium text-black bg-yellow-500 rounded-md shadow-sm"
                onClick={() => {
                  setOpenFundAssetsModal(false);
                }}
              >
                Okay
              </RoundedButton>
            </div>
          )}
        </div>
      ) : (
        <>
          <h1 className="w-10/12 mx-auto text-lg font-normal text-center text-black md:w-11/12 md:text-xl dark:text-white font-integral">
            Wallet Balance is too Low to Fund Assets Vault
          </h1>
          <p className="w-10/12 mx-auto my-6 text-base font-light text-black break-words md:w-11/12 dark:text-gray-300">
            Not enough{" "}
            {window.localStorage.getItem("network") !== "bitcoin"
              ? "tSats"
              : "Sats"}{" "}
            to create the required UTXOs. RGB{" "}
            {window.localStorage.getItem("network") !== "bitcoin"
              ? "testnet"
              : ""}{" "}
            transactions currently require on-chain{" "}
            {window.localStorage.getItem("network") !== "bitcoin"
              ? "tSats"
              : "Sats"}{" "}
            to receive.
          </p>
          <RoundedButton
            className="inline-flex justify-center w-10/12 px-4 mt-1.5 mb-3 mx-auto text-base font-medium text-black bg-yellow-500 rounded-md shadow-sm max-w-xs"
            onClick={() => {
              setOpenFundAssetsModal(false);
            }}
          >
            Okay
          </RoundedButton>
        </>
      )}
    </div>
  );
};

export default FundAssets;
